import { api } from '@/config/axios/index';

export default {
  getCategories() {
    return api({
      method: 'GET',
      url: `${process.env.VUE_APP_API_ROUTE}/category`,
    });
  },

  getSingleCategorySubjects(category) {
    return api({
      method: 'GET',
      url: `${process.env.VUE_APP_API_ROUTE}/category/${category}/subject`,
    });
  },

  getSubjectData(data) {
    return api({
      method: 'GET',
      url: `${process.env.VUE_APP_API_ROUTE}/subject/${data}`,
    });
  },

  getMostAccessedData() {
    return api({
      method: 'GET',
      url: `${process.env.VUE_APP_API_ROUTE}/subject/mostaccessed`,
    });
  },

  getSearchData(){
    return api({
      method: 'GET',
      url: `${process.env.VUE_APP_API_ROUTE}/search/category/subject`,
    });
  },

  getVotes(subject){
    return api({
      method: 'GET',
      url: `${process.env.VUE_APP_API_ROUTE}/vote/${subject}`,
    });
  },

  postVote(voteInfo){
    return api({
      method: 'POST',
      url: `${process.env.VUE_APP_API_ROUTE}/vote`,
      data: voteInfo
    });
  },

  fetchSvg(svgUrl) {
    return api({
      method: 'GET',
      url: svgUrl,
    });
  },

  fetchHighlights() {
    return api({
      method: 'GET',
      url: `${process.env.VUE_APP_API_ROUTE}/subject/highlight`,
    });
  },

  fetchReports() {
    return api({
      method: 'GET',
      url: `${process.env.VUE_APP_API_ROUTE}/externalReport`,
    });
  },

  fetchHomeData() {
    return api({
      method: 'GET',
      url: `${process.env.VUE_APP_API_ROUTE}/home`,
    });
  },
};
