module.exports = {
  CONTENT: {
    CITY: {
      name: 'Upcities',
      title: 'Prefeitura',
      pageTitle: 'Ouvidoria',
      site: 'https://upcities.app/',
      NUMBER: {
        local: '160 - Upcities',
        other: '0800.000.0000 - Upcities',
        optional: '(27) 3149-7336',
        workingHours: 'Segunda à sexta, das 08h às 21h:'
      },
      LINKS: {
        facebook: '',
        linkedin: '',
        instagram: '',
        youtube: '',
        admin: 'https://upticket.uppersoft.cc/',
        aboutUrl: '',
        statisticsUrl: '',
        localNo: 'tel:160',
        otherNo: 'tel:0800.000.0000',
        optional: '(27) 3149-7336'
      },
    },
    SUBHEADER: {
      text: 'Prefeitura Upcities',
      link: 'Information',
      //se link for interno adicionar o name da rota, links externos devem começar com http
    },
    HOME: {
      BANNER: {
        title: 'COMO PODEMOS TE AJUDAR?',
      },
      SERVICES: {
        title: 'Categorias de Serviços',
        subtitle: 'Selecione uma categoria para ver os serviços e informações disponíveis',
      },
      HELPBANNER: {
        title: 'Dê sua opinião!',
        subtitle: 'Seu feedback é importante para nós! Clique para escolher como deseja avaliar sua experiência.',
      },
      CARDS: {
        //url: nome da rota ou link externo
        DATA: {
          title: 'Acesse todas as categorias',
          subtitle: 'Explore as categorias disponíveis e saiba como ser atendido da melhor maneira',
          link: 'Ver todas',
          url: 'CategoryAll'
        },
        ACOMPANHE: {
          title: 'Legislação e Relatórios',
          subtitle: 'Acesse leis e relatórios governamentais para entender seus direitos e obrigações',
          link: 'Acessar documentos',
          url: 'Docs',
        },
        ATENDIMENTO: {
          title: 'Outros canais de atendimento',
          subtitle: 'Veja nossas opções de contato, incluindo atendimento por telefone ou presencial',
          link: 'Ver canais',
          url: 'Home'
        },
        DUVIDAS: {
          title: 'Dúvidas frequentes',
          subtitle: 'Encontre respostas para as perguntas mais comuns dos cidadãos',
          link: 'Tirar dúvidas',
          url: 'Faq'
        }
      },
      MOSTREQUESTEDBANNER: {
        title: 'Assuntos mais solicitados',
      },
      APPBANNER: {
        title: 'EXPERIMENTE PELO APP',
        altTitle: 'Experimente o aplicativo de "Cidade"!',
        subtitle: 'Baixe o app para interagir ainda mais com a prefeitura de Upcities. Informações, orientações e serviços em um único lugar de forma intuitiva, rápida e fácil.',
        LINKS: {
          googlePlay: 'https://upcities.app/',
          appleStore: 'https://upcities.app/',
        }
      }
    },
    CATEGORIES: {
      title: 'Nossos serviços',
      subtitle: 'Escolha uma categoria para ver as informações e serviços relacionados',
    },
    SOLICITATIONS: {
      title: 'Acompanhe suas solicitações',
      search: 'Suas solicitações',
    },
    FAQ: {
      title: 'Dúvidas Frequentes',
    },
    AUTOCOMPLETE: {
      LOCATION: {
        lat: '-20.316457',
        lng: '-40.2915037',
      },
      addressError: 'São permitidos apenas endereços da cidade de UpCities'
    },
    INFOACCESS: {
      dashboard: 'Dados dos Pedidos de Informação',

      // os textos abaixo definem se os cards serao mostrados pela presença da rota ou link,
      // se link for interno adicionar o name da rota, links externos devem começar com http
      INDEX: {
        requestInformation: {
          id: 0,
          icon: 'InfoSquare',
          text: 'Faça seu Pedido de Informação pela Internet',
          link: 'ManifestationType'
        },
        cards: [
          {
            id: 1,
            icon: 'EyeSquare',
            text: 'Acompanhar Pedido de Informação',
            link: 'Solicitations'
          },
          {
            id: 2,
            icon: 'PeopleMic',
            text: 'Faça seu Pedido de Informação pessoalmente ou por Telefone',
            link: 'InfoContact'
          },
          {
            id: 3,
            icon: 'Faq',
            text: 'Perguntas Frequentes',
            link: 'InfoFAQ'
          },
          {
            id: 4,
            icon: 'Gavel',
            text: 'Legislações',
            link: 'InfoLegislation'
          },
          {
            id: 5,
            icon: 'DataSquare',
            text: 'Relatórios',
            link: 'InfoReports'
          },
          {
            id: 6,
            icon: 'Guide',
            text: 'Orientações sobre o Pedido de Acesso à Informação',
            link: 'InfoOrientation'
          },
          {
            id: 7,
            icon: 'ClipboardWatch',
            text: 'Informações sobre Prazos e Recursos',
            link: 'InfoDeadlines'
          }
        ],
      },

      CONTACT: {
        headerText: 'A Lei nº 12.527/2011, sancionada pela Presidenta da República em 18 de novembro de 2011, tem o propósito de regulamentar o direito constitucional de acesso dos cidadãos às informações públicas e seus dispositivos são aplicáveis aos três Poderes da União, Estados, Distrito Federal e Municípios. <br><br> A publicação da Lei de Acesso a Informações significa um importante passo para a consolidação democrática do Brasil e também para o sucesso das ações de prevenção da corrupção no país. Por tornar possível uma maior participação popular e o controle social das ações governamentais, o acesso da sociedade às informações públicas permite que ocorra uma melhoria na gestão pública.',
        cityLaw: 'O Decreto Nº 253/2012, publicado em 25 de setembro de 2012, trata-se da regulamentação específica da Lei de Acesso à Informação no âmbito do Poder Executivo Municipal de Vila Velha, tendo estabelecido os procedimentos para a garantia do acesso a informações previsto na Lei Federal Nº 12.527/2011.',
        inPersonContact: [
          'Para realizar o pedido de acesso à informação presencialmente, o cidadão deverá dirigir-se ao Serviço de Informação ao Cidadão (SIC), na Ouvidoria Geral da Prefeitura Municipal de Vila Velha, localizada na Avenida Santa Leopoldina, nº 840, 3º andar - Coqueiral de Itaparica - Vila Velha/ES CEP: 29102-375, preencher o formulário de solicitação e anotar o número de protocolo para acompanhar o andamento do seu pedido.',
          'Além disso, o cidadão também poderá entrar com recurso, reclamação ou com pedido de desclassificação da informação, seguindo os mesmos passos descritos acima.'
        ],
        mailContact: [
          'Setor Responsável:',
          'Ouvidoria Geral / Prefeitura Municipal de Vila Velha',
          'Endereço:',
          'Avenida Santa Leopoldina, nº 840, 3º andar - Coqueiral de Itaparica - Vila Velha/ES',
          'CEP: 29102-375'
        ],
        phone: [
          {
            number: '162',
            label: '(Municipio)'
          },
          {
            number: '0800 283 9059',
            label: '(outras localidades)'
          }
        ],
        email: 'ouvidoriavv@vilavelha.es.gov.br',
        time: '08:00 às 17:00',
        important: [
          'O Serviço de Informações ao Cidadão (SIC) permite que qualquer pessoa, física ou jurídica, encaminhe, por qualquer meio legítimo (presencial, correspondência ou meio eletrônico), pedidos de acesso à informação, acompanhe o prazo e receba a resposta da solicitação realizada para a Prefeitura Municipal de Vila Velha. O cidadão ainda pode entrar com recursos e apresentar reclamações sem burocracia.',
          'Ao fazer o seu pedido de informação, em um prazo máximo de 20 dias, a informação será enviada para o endereço de e-mail cadastrado.',
          'No caso de indeferimento de acesso a informações ou às razões da negativa do acesso, você poderá entrar com recursos contra a decisão e apresentar reclamações sem burocracia no prazo de 10 dias a contar da sua ciência.',
          'Em caso de interposição de recurso, deverá ser aberto um novo pedido de Lei de Acesso à Informação no qual o munícipe deverá informar que está solicitando um recurso e citar o protocolo do pedido anterior. Informações sobre a autoridade competente para o exame de recurso poderão ser acessadas no Portal da Transparência.'
        ]
      },

      LEGISLATION: [
        {
          label: 'Lei Federal nº 12.527/2011',
          text: 'A Lei nº 12.527/2011, sancionada pela Presidenta da República em 18 de novembro de 2011, tem o propósito de regulamentar o direito constitucional de acesso dos cidadãos às informações públicas e seus dispositivos são aplicáveis aos três Poderes da União, Estados, Distrito Federal e Municípios. A publicação da Lei de Acesso à Informação significa um importante passo para a consolidação democrática do Brasil e também para o sucesso das ações de prevenção da corrupção no país. Por tornar possível uma maior participação popular e o controle social das ações governamentais, o acesso da sociedade às informações públicas permite que ocorra uma melhoria na gestão pública.',
          link: 'http://www.planalto.gov.br/ccivil_03/_ato2011-2014/2011/lei/l12527.htm'
        },
        {
          label: 'Decreto Municipal nº 253/2012',
          text: 'O Decreto nº 253/2012, publicado em 25 de setembro de 2012, trata-se da regulamentação específica da Lei de Acesso à Informação no âmbito do Poder Executivo Municipal de Vila Velha, tendo estabelecido os procedimentos para a garantia do acesso a informações previsto na Lei Federal nº 12.527/2011.',
          link: 'https://processos.vilavelha.es.gov.br/Arquivo/Documents/legislacao/html/D2532012.html?identificador=39003600360037003A004C00'
        }
      ],

      FAQ: [
        {
          id: 1,
          question: 'Quem é obrigado a cumprir a Lei de Acesso à Informação?',
          answer: 'A Lei de Acesso à Informação brasileira se aplica a toda a administração pública, ou seja, a todos os órgãos e entidades dos poderes Executivo, Legislativo e Judiciário, da União, dos Estados, do Distrito Federal e dos Municípios, bem como a todos os Tribunais de Contas e ao Ministério Público (art. 1°). Além da administração pública, a Lei abrange as entidades privadas sem fins lucrativos que recebem recursos públicos (art. 2°).'
        },
        {
          id: 2,
          question: 'O acesso a informação é o mesmo que acesso a documentos?',
          answer: 'É importante ter claro que a Lei 12.527 regulamenta o direito de acesso a informações públicas, e não somente a documentos públicos. Portanto, o acesso da sociedade não está restrito a informações contidas em documentos registrados e formalmente identificados, tais como ofícios, memorandos, relatórios, processos ou atas de reunião, mas abrange também o acesso a quaisquer dados e informações que possam ser úteis para a produção e transmissão de conhecimento, contidos em planilhas, gráficos, documentos físicos, eletrônicos, digitalizados, vídeos, áudios, etc. independente de registro em sistemas de protocolo.'
        },
        {
          id: 3,
          question: 'O que é a Lei de Acesso à Informação?',
          answer: 'A Lei nº 12.527/2011, conhecida como Lei de Acesso à Informação - LAI regulamenta o direito, previsto na Constituição, de qualquer pessoa solicitar e receber dos órgãos e entidades públicos, de todos os entes e Poderes, informações públicas por eles produzidas ou custodiadas.'
        },
        {
          'id': 4,
          'question': 'Quando a Lei de Acesso à Informação entrou em vigor?',
          'answer': 'A LAI foi publicada em 18 de novembro de 2011, mas só entrou em vigor 180 (cento e oitenta) dias após essa data, ou seja, em 16 de maio de 2012.'
        },
        {
          'id': 5,
          'question': 'O que são informações?',
          'answer': 'De acordo com o art. 4°, inciso I, da Lei nº 12.527/2011, informações são dados, processados ou não, que podem ser utilizados para produção e transmissão de conhecimento, registrados em qualquer suporte ou formato.'
        },
        {
          'id': 6,
          'question': 'A que tipo de informação os cidadãos podem ter acesso pela Lei de Acesso?',
          'answer': 'Com a Lei de Acesso, a publicidade passou a ser a regra e o sigilo a exceção. Dessa forma, as pessoas podem ter acesso a qualquer informação pública produzida ou custodiada pelos órgãos e entidades da Administração Pública. A Lei de Acesso, entretanto, prevê algumas exceções ao acesso às informações, notadamente àquelas cuja divulgação indiscriminada possa trazer riscos à sociedade ou ao Município, Estado ou União.'
        },
        {
          'id': 7,
          'question': 'É preciso justificar o pedido de acesso à informação?',
          'answer': 'Não. De acordo com o art. 10, § 8° da Lei de Acesso, é proibido exigir que o solicitante informe os motivos de sua solicitação. Entretanto, o órgão/entidade pode dialogar com o cidadão para entender melhor a demanda, de modo a fornecer a informação mais adequada a sua solicitação.'
        },
        {
          'id': 8,
          'question': 'O acesso à informação é gratuito?',
          'answer': 'Conforme dispõe o art. 12 da Lei de Acesso à Informação, o serviço de busca e fornecimento da informação é gratuito. Entretanto, podem ser cobrados os custos dos serviços e dos materiais utilizados na reprodução e envio de documentos. Neste caso, o órgão ou entidade deverá disponibilizar ao solicitante uma Guia de Recolhimento ou documento equivalente para que ele possa realizar o pagamento.'
        },
        {
          'id': 9,
          'question': 'Quais são os prazos para resposta dos pedidos apresentados com base na Lei de Acesso à Informação?',
          'answer': 'Se a informação estiver disponível, ela deve ser entregue imediatamente ao solicitante. Caso não seja possível conceder o acesso imediato, o órgão ou entidade tem até 20 (vinte) dias para atender ao pedido, prazo que pode ser prorrogado por mais 10 (dez) dias, se houver justificativa expressa.'
        },
        {
          'id': 10,
          'question': 'O que é transparência ativa?',
          'answer': 'É a divulgação de dados por iniciativa do próprio setor público, ou seja, quando são tornadas públicas informações, independente de requerimento, utilizando principalmente a Internet. Um exemplo de transparência ativa são as seções de acesso a informações dos sites dos órgãos e entidades. Os portais de transparência também são um exemplo disso. A divulgação proativa de informações de interesse público, além de facilitar o acesso das pessoas e de reduzir o custo com a prestação de informações, evita o acúmulo de pedidos de acesso sobre temas semelhantes.'
        },
        {
          'id': 11,
          'question': 'O que é transparência passiva?',
          'answer': 'É a disponibilização de informações públicas em atendimento a demandas específicas de uma pessoa física ou jurídica. Por exemplo, a resposta a pedidos de informação registrados para determinada Secretaria, seja por meio do SIC físico do órgão ou pelo e-SIC (Sistema Eletrônico do Serviço de Informação ao Cidadão).'
        },
        {
          'id': 12,
          'question': 'O que é o SIC?',
          'answer': 'O art. 9° da Lei de Acesso instituiu como um dever do Estado à criação de um ponto de contato entre a sociedade e o setor público, que é o Serviço de Informações ao Cidadão - SIC. São funções do SIC: a) atender e orientar os cidadãos sobre pedidos de informação; b) informar sobre a tramitação de documentos e requerimentos de acesso à informação; c) receber e registrar os pedidos de acesso e devolver as respostas aos solicitantes. Cada órgão e entidade do poder público deve se estruturar para tornar efetivo o direito de acesso à informação, sendo obrigatória a instalação do SIC pelo menos em sua sede, em local de fácil acesso e identificação pela sociedade.'
        },
        {
          'id': 13,
          'question': 'O que é o e-SIC?',
          'answer': 'O Sistema Eletrônico do Serviço de Informação ao Cidadão (e-SIC) é um sistema que centraliza as entradas e saídas de todos os pedidos de acesso dirigidos ao Poder Executivo. O objetivo do e-SIC é organizar e facilitar os procedimentos de acesso à informação tanto para os cidadãos quanto para a Administração Pública. O e-SIC permite que qualquer pessoa - física ou jurídica - encaminhe pedidos de acesso à informação para órgãos e entidades do Poder Executivo. Por meio do sistema também é possível consultar as respostas recebidas; entrar com recursos; apresentar reclamações; entre outras ações.'
        },
        {
          'id': 14,
          'question': 'O que é o Decreto Nº 253/2012?',
          'answer': 'É a regulamentação específica da Lei de Acesso à Informação no âmbito do Poder Executivo Municipal de Vila Velha, com sua publicação em 25 de setembro de 2012, que estabeleceu os procedimentos para a garantia do acesso à informação e para a classificação de informações sob restrição de acesso no Município de Vila Velha.'
        },
        {
          'id': 15,
          'question': 'Quais informações os órgãos e entidades do Poder Executivo Municipal são obrigados a disponibilizar pro ativamente em seus sites?',
          'answer': 'O art. 7° do Decreto 253/2012 definiu como um dever dos órgãos e entidades públicas publicar na internet informações públicas de interesse coletivo ou geral. De acordo com o Decreto, os órgãos e entidades de sua Administração Direta ou Indireta deverão publicar o seguinte rol mínimo de informações nos seus sítios eletrônicos: I) registro das competências e estrutura organizacional dos órgãos e entidades de sua Administração Direta ou Indireta, além dos endereços e telefones de suas unidades e horários de atendimento ao público; II) registros de repasses ou transferências de recursos financeiros; III) registro das despesas; IV) informações concernentes a procedimentos licitatórios, inclusive os respectivos editais e resultados, bem como a todos os contratos celebrados; V) dados gerais parar o acompanhamento de programas, projetos, ações e obras; VI) respostas a perguntas mais frequentes da sociedade.'
        },
        {
          'id': 16,
          'question': 'Quem pode fazer um pedido de acesso à informação?',
          'answer': 'O Artigo 8º do Decreto 253/2012 definiu que, qualquer interessado poderá apresentar pedido de acesso a informação aos órgãos e entidades da Administração Direta e Indireta do Poder Executivo, por meio do Serviço de Informação ao Cidadão – SIC, no setor de atendimento da Ouvidoria Geral do Município, devendo o pedido conter a identificação do requerente e a especificação da informação requerida.'
        },
        {
          'id': 17,
          'question': 'Quais são os prazos para resposta dos pedidos apresentados com base no Decreto?',
          'answer': 'Se a informação estiver disponível, ela deve ser entregue imediatamente ao solicitante. Caso não seja possível conceder o acesso imediato, o órgão ou entidade tem até 20 (vinte) dias para atender ao pedido, prazo que pode ser prorrogado por mais 10 (dez) dias, se houver justificativa expressa, conforme consta em seu art. 9.'
        },
        {
          'id': 18,
          'question': 'O meu pedido pode ser negado?',
          'answer': 'Sim. Como regra, as informações produzidas pelo setor público são públicas e devem estar disponíveis à sociedade. No entanto, há alguns tipos de informações que, se divulgadas, podem colocar em risco a segurança da sociedade ou do Município. Em se tratando de informações pessoais e sigilosas, o Decreto estabelece que o Município tem o dever de protegê-las. Estas informações devem ter acesso restrito e serem resguardadas não só quanto à sua integridade, mas contra vazamentos e acessos indevidos. Se o pedido de acesso for negado, é direito de o requerente receber comunicação que contenha as razões da negativa e seu fundamento legal, as informações para recurso, bem como sobre os prazos e condições para tal (§ 2 do art. 9º).'
        },
        {
          'id': 19,
          'question': 'Quais informações podem ser negadas?',
          'answer': 'Poderão ser negadas as informações: a) Genéricas; b) Desproporcionais ou desarrazoadas; ou c) Que exijam trabalhos adicionais de análise, interpretação ou consolidação de dados e informações, ou serviço de produção ou tratamento de dados que não seja de competência do órgão ou entidade.'
        },
        {
          'id': 20,
          'question': 'O que posso fazer caso o órgão ou entidade negue o acesso à informação?',
          'answer': 'Caso o órgão ou entidade negue o acesso à informação ou não forneça as razões da negativa de acesso, você poderá, no prazo de 10 (dez) dias contados da ciência da decisão, apresentar recurso junto à Comissão de Recursos.'
        },
        {
          'id': 21,
          'question': 'O que são informações pessoais?',
          'answer': 'Informações pessoais são aquelas relacionadas à pessoa natural identificada ou identificável. As informações relativas à intimidade, vida privada, honra e imagem das pessoas devem ter seu acesso restrito por 100 anos (art. 21, §1°, I do Decreto nº 253/2012), independentemente de classificação, e só podem ser acessadas pela própria pessoa; por agentes públicos legalmente autorizados; por terceiros autorizados diante de previsão legal ou consentimento expresso da pessoa a que as informações se referirem.'
        },
        {
          'id': 22,
          'question': 'Quais informações são classificadas como sigilosas?',
          'answer': 'São as informações consideradas imprescindíveis à segurança da sociedade e do Poder Executivo, exercido pela Administração Direta e Indireta do Município e, portanto, passíveis de classificação, as informações cuja divulgação ou acesso irrestrito possam: I - por risco a defesa e a integridade do território do Município; II - prejudicar ou por em risco a condução de negociações ou as relações internacionais de que participe o Município, ou as que tenham sido fornecidas em caráter sigiloso por outros entes e organismos internacionais; III - por em risco a vida, a segurança ou a saúde da população; IV - oferecer risco à estabilidade financeira, econômica ou monetária do Município; V - prejudicar ou causar risco a planos ou operações estratégicas dos órgãos de segurança sediadas no Município; VI - prejudicar ou causar risco a projetos de pesquisa e desenvolvimento científico ou tecnológico, assim como a sistemas, bens, instalações ou áreas de interesse estratégico; VII - por em risco a segurança de instituições ou de altas autoridades nacionais, estaduais ou estrangeiras e seus familiares; VIII - comprometer atividades de inteligência, bem como de investigação ou fiscalização em andamento, relacionadas'
        },
        {
            'id': 23,
            'question': 'Quem classifica as informações como sigilosas?',
            'answer': 'Consideram-se informações protegidas pelo sigilo aquelas que foram qualificadas pela Comissão Mista de Reavaliação de Informações, criada pelo art. 22 do presente Decreto.'
        },
        {
            'id': 24,
            'question': 'O que é a Comissão Mista de Reavaliação de Informações (CMRI)?',
            'answer': 'É uma comissão composta pelos titulares da Controladoria Geral, da Procuradoria Geral do Município, da Secretaria Municipal de Administração, da Secretaria Municipal de Finanças e da Secretaria Municipal de Governo, que decidirá no âmbito da administração pública municipal, sobre o tratamento e a classificação de informações sigilosas. Nos termos do art. 22.'
        },
        {
            'id': 25,
            'question': 'É necessário que cada estado, município tenham legislação própria regulamentando os procedimentos relativos ao direito de acesso à informação?',
            'answer': 'Sim, é necessário. A Lei de Acesso à Informação contém dispositivos gerais, aplicáveis indistintamente a todos os que estão sujeitos a ela, e alguns dispositivos que são aplicáveis somente ao Poder Executivo Federal. O art. 45 da Lei define que cabe aos Estados, ao Distrito Federal e aos Municípios definir suas regras específicas em legislação própria, obedecidas as normas gerais estabelecidas na Lei de Acesso. É importante ressaltar que os dispositivos gerais têm aplicação imediata. Portanto, a falta de regulamentação específica prejudica, mas não impede o cumprimento da Lei.'
        },
        {
            'id': 26,
            'question': 'O que é um recurso?',
            'answer': 'É o direito de mostrar-se insatisfeito diante da resposta concedida pelo órgão ou entidade. O cidadão poderá apresentar recurso, no prazo de 10 (dez) dias contados da ciência da resposta.'
        },
        {
            'id': 27,
            'question': 'Que condutas ilícitas podem ensejar responsabilidade do agente público?',
            'answer': 'O agente público poderá ser responsabilizado caso não forneça informações públicas requeridas. O art. 23 do Decreto define como condutas ilícitas que podem ensejar responsabilidade: I - recusar-se a fornecer informação requerida nos termos deste Decreto, retardar deliberadamente o seu fornecimento ou fornecê-la intencionalmente de forma incorreta, incompleta ou imprecisa; II - utilizar indevidamente, bem como subtrair, destruir, inutilizar, desfigurar, alterar ou ocultar, total ou parcialmente, informação que se encontre sob sua guarda ou a que tenha acesso ou conhecimento em razão do exercício das atribuições de cargo, emprego ou função pública; III - agir com dolo ou má-fé na análise das solicitações de acesso à informação; IV - divulgar ou permitir a divulgação ou acessar ou permitir acesso indevido à informação sigilosa ou informação pessoal; V - impor sigilo à informação para obter proveito pessoal ou de terceiro, ou para fins de ocultação de ato ilegal cometido por si ou por outrem; VI - ocultar da revisão de autoridade superior competente informação sigilosa para beneficiar a si ou a outrem, ou em prejuízo de terceiros; e VII - destruir ou subtrair, por qualquer meio, documentos concernentes a possíveis violações de direitos humanos por parte de agentes públicos.'
        },
        {
            'id': 28,
            'question': 'Que sanções podem ser aplicadas a agentes públicos em caso de descumprimento do Decreto 253/2012?',
            'answer': 'O agente público que descumprir propositalmente as obrigações trazidas pelo Decreto poderá ser punido, conforme dispõe o Estatuto dos Servidores do Município de Vila Velha. Além disso, o agente poderá responder por improbidade administrativa.'
        },
        {
            'id': 29,
            'question': 'Qual o papel da SEMCONT no monitoramento do Decreto 253/2012 no Poder Executivo?',
            'answer': 'A SEMCONT é o órgão da estrutura da Administração Direta do Município a quem caberá verificar e avaliar o estado em que se encontram os arquivos existentes nos diversos sistemas e nos arquivos passivos nos diversos órgãos municipais e recomendar as medidas para a sua atualização, implementação e aprimoramento, garantido assim o que dispõe o art. 7º, inciso II, da Lei Federal nº 12.527, de 2011.'
        }
      ],

      DEADLINES: [
        {
          title: 'Prazos e Recursos',
          text: 'Ao fazer o seu pedido de informação, em um prazo máximo de 20 dias, a informação será enviada para o endereço de e-mail cadastrado. No caso de indeferimento de acesso a informações ou às razões da negativa do acesso, você poderá entrar com recursos contra a decisão e apresentar reclamações sem burocracia no prazo de 10 dias a contar da sua ciência. Em caso de interposição de recurso, deverá ser aberto um novo Pedido de Acesso à Informação no qual o solicitante deverá informar que está solicitando um recurso e citar o protocolo do pedido anterior, quando for o caso. O recurso será encaminhado à Comissão Mista de Reavaliação de Informações, que é a autoridade competente para o exame de recurso. Para saber mais sobre a autoridade competente para interposição de recursos, acesse aqui.',
          link: {
            text: 'acesse aqui',
            ref: 'https://transparencia.vilavelha.es.gov.br/pagina.aspx?id=10'
          }
        },
        {
          title: 'Lei Federal nº 12.527/2011',
          text: 'A Lei nº 12.527/2011, sancionada pela Presidenta da República em 18 de novembro de 2011, tem o propósito de regulamentar o direito constitucional de acesso dos cidadãos às informações públicas e seus dispositivos são aplicáveis aos três Poderes da União, Estados, Distrito Federal e Municípios. A publicação da Lei de Acesso à Informação significa um importante passo para a consolidação democrática do Brasil e também para o sucesso das ações de prevenção da corrupção no país. Por tornar possível uma maior participação popular e o controle social das ações governamentais, o acesso da sociedade às informações públicas permite que ocorra uma melhoria na gestão pública.',
          link: {
            text: 'Lei nº 12.527/2011',
            ref: 'https://processos.vilavelha.es.gov.br/Arquivo/Documents/legislacao/html/D2532012.html?identificador=39003600360037003A004C00'
          }
        },
        {
          title: 'Vazio',
          text: 'Testando um tópico de texto sem links',
          link: {}
        }
      ],

      ORIENTATION: {
        content: [
          {
            title: 'Pedido de Acesso à Informação',
            text: [
              'Você tem o direito de solicitar acesso a informações produzidas ou mantidas pelos órgãos e entidades públicas, de interesse particular ou coletivo, no entanto, existem aquelas que podem ser disponibilizadas sem nenhuma restrição e outras que devem ser protegidas por seu caráter restritivo.',
              'Caso em um mesmo documento coexistam informações com e sem restrição de acesso, você tem o direito de conhecer a parte sem restrição. A publicidade é a regra, o sigilo, a exceção. Hoje é o governo quem tem a obrigação de provar que determinada informação não pode ser concedida, uma vez que, em regra, as informações da Administração podem ser solicitadas e disponibilizadas via LAI.',
            ]
          },
          {
            title: 'O que não é um Pedido de Informação?',
            text: [
              'É importante não confundir manifestações de ouvidoria com pedidos de informação. As manifestações não têm por objetivo o acesso à informação propriamente dito. A manifestação é uma forma de o cidadão expressar seus anseios, angústias, dúvidas, opiniões e sua satisfação com um atendimento ou serviço recebido. Veja a seguir os tipos de manifestação:',
              'Reclamação: demonstração de insatisfação relativa a serviço público, Desabafos. ',
              'Solicitação: requerimento de adoção de providência por parte da Administração. ',
              'Sugestão: apresentação de proposta de melhoria dos serviços públicos.',
              'Elogio:  demonstração de satisfação com atendimento ou serviço.',
              'Denúncia: comunicação de prática de ato ilícito cuja solução dependa da atuação de órgão de controle interno ou externo.',
              'Adicionalmente, vale lembrar que consultas também não são pedidos de informação. As consultas seriam a solicitação de interpretação de algum normativo, ou, ainda, solicitação de opinião de órgão sobre um determinado assunto. Atualmente, consultas não são aceitas como pedidos de acesso à informação quando o órgão não tenha realizado a análise de um caso semelhante e sobre ele produzido um documento.',
            ]
          },
          {
            title: 'Como fazer um Pedido de Informação?',
            text: [
              'Para fazer seu pedido, procure o Serviço de Informação ao Cidadão (SIC) do órgão e entidade de seu interesse. O SIC é a unidade responsável por receber e atender os pedidos de informação feitos com base na LAI. Todo ente público dever ter um SIC.',
              'É possível ainda protocolar pedidos de informação pessoalmente, por e-mail, por telefone ou até pelos correios.',
              'O pedido pode ser feito pela internet, da seguinte forma:',
              'Acesse o ícone “Acesso à Informação”. Caso você nunca tenha usado o sistema, cadastre-se;',
              'Faça o Login;',
              'Selecione a opção “Faça seu Pedido de Acesso a Informação pela Internet”;',
              'Preencha os dados requeridos e o formulário de requisição de informação;',
              'Ao final do processo, o sistema informará um número de protocolo, que também será enviado para seu e-mail. Guarde esse número! Ele é a forma mais rápida de acompanhar seu pedido futuramente.',
              'Para fazer um pedido de informação presencial, vá ao SIC:',
              'Ouvidoria Geral / Prefeitura Municipal de Vila Velha',
            ]
          },
          {
            title: 'Endereço:',
            text: [
              'Avenida Santa Leopoldina, nº 840, 3º andar - Coqueiral de Itaparica - Vila Velha/ES CEP: 29102-375',
            ]
          },
          {
            title: 'Como acompanhar seu Pedido de Informação?',
            text: [
              'Consulte o andamento da sua solicitação usando o seu número de protocolo.',
              'Você receberá em e-mail se a sua solicitação tiver o prazo prorrogado ou for reencaminhada.',
            ]
          },
          {
            title: 'Obtenção da sua Resposta',
            text: [
              'Sempre que possível, a resposta será dada da forma de sua preferência: por meio eletrônico, por correspondência (com custos) ou por busca/consulta no próprio órgão (que pode ter custos ou não).'
            ]
          }
        ]
      }
    },

    MANIFESTATION: {
      corruptionComplaint: 'https://fala.cachoeiro.es.gov.br:8443/DenunciaCorrupcao.aspx?idTipo=11'
    },

    DOCS: {
      cards: [
        {
          id: 1,
          icon: 'Gavel',
          text: 'Legislações',
          link: 'DocLegislation'
        },
        {
          id: 2,
          icon: 'DataSquare',
          text: 'Relatórios',
          link: 'DocReports'
        }
      ],
      legislation: [
        {
          label: 'Decreto nº 28.824',
          text: 'Regulamenta a aplicação das disposições da lei federal nº 13.460 de 26/06/2017, que dispõe sobre a participação, proteção e defesa dos direitos do usuário de serviços públicos da administração pública',
          link: 'https://leis.cachoeiro.es.gov.br:8081/portalcidadao/arquivos/Legislacao/DECRETO288242019.html',
        },
        {
          label: 'Decreto nº 28.903',
          text: 'Regulamenta a aplicação das disposições da lei federal nº 13.460 de 26/06/2017, que dispõe sobre a participação, proteção e defesa dos direitos do usuário de serviços públicos da administração pública.',
          link: 'https://leis.cachoeiro.es.gov.br:8081/portalcidadao/arquivos/Legislacao/DECRETO289032019.html',
        },
        {
          label: 'Decreto nº 31.831',
          text: 'Dispõe sobre as salvaguardas de proteção à identidade dos denunciantes de ilícitos e de irregularidades praticadas contra a administração pública municipal direta e indireta, e dá outras providências.',
          link: 'https://cachoeiro.legislacaocompilada.com.br/Arquivo/Documents/legislacao/image/D318312022.pdf?identificador=30003A004C00',
        },
      ],
    }
  }
};