// eslint-disable
import Vue from 'vue';
import VueI18n from 'vue-i18n';

// Vee Validate
import validationPtBR from 'vee-validate/dist/locale/pt_BR.json';

// Strings
import stringPtBR from './string/pt_BR';

// Texts
import upcities from '../../assets/theme/upcities/labels';
import pmcariacica from '../../assets/theme/pmcariacica/labels';
import pmvv from '../../assets/theme/pmvv/labels';
import pmci from '../../assets/theme/pmci/labels';
import oabes from '../../assets/theme/oabes/labels';

let assetPath;

switch (process.env.VUE_APP_KEYCLOAK_REALM) {
  case 'Uppersoft':
    assetPath = upcities.CONTENT;
    break;
  case 'pmcariacica':
    assetPath = pmcariacica.CONTENT;
    break;
  case 'pmvv':
    assetPath = pmvv.CONTENT;
    break;
  case 'pmci':
    assetPath = pmci.CONTENT;
    break;
  case 'oabes':
    assetPath = oabes.CONTENT;
    break;
  default:
    assetPath = upcities.CONTENT;
    break;
}

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: (window.navigator.userLanguage || window.navigator.language).substring(0, 2),
  fallbackLocale: 'pt_BR',
  silentTranslationWarn: true,
  messages: {
    pt: {
      ...assetPath,
      validation: {
        ...stringPtBR.VALIDATION,
        ...validationPtBR.messages,
      },
    },
    en: {
      ...assetPath,
      validation: {
        ...stringPtBR.VALIDATION,
        ...validationPtBR.messages,
      },
    },
    es: {
      ...assetPath,
      validation: {
        ...stringPtBR.VALIDATION,
        ...validationPtBR.messages,
      },
    },
  },
});

export default i18n;
