import { api } from '@/config/axios/index';
//import ticketApi from '@/config/axios/ticket';

export default {
  getTickets() {
    return api({
      method: 'GET',
      url: `${process.env.VUE_APP_API_ROUTE}/ticket`,
    });
  },

  getTicket(ticketId) {
    return api({
      method: 'GET',
      url: `${process.env.VUE_APP_API_ROUTE}/ticket/${ticketId}`,
    });
  },

  getWppTicket(wppCode) {
    return api({
      method: 'GET',
      url: `${process.env.VUE_APP_API_ROUTE}/ticket/whatsapp/${wppCode}`
    });
  },

  createTicket( ticket ) {
    return api({
      method: 'POST',
      url: `${process.env.VUE_APP_API_ROUTE}/ticket`,
      data: ticket,
    });
  },

  cancelTicket(ticketId, reason) {
    return api({
      method: 'POST',
      url: `${process.env.VUE_APP_API_ROUTE}/ticket/${ticketId}/cancel`,
      data: reason,
    });
  },

  getReason() {
    return api({
      method: 'GET',
      url: `${process.env.VUE_APP_API_ROUTE}/reason`,
    });
  },

  addAnswer(ticketId, answer) {
    return api({
      method: 'POST',
      url: `${process.env.VUE_APP_API_ROUTE}/ticket/${ticketId}/issuerAnswer`,
      data: answer,
    });
  },

  anonymousTicket( ticketCode ) {
    return api({
      method: 'GET',
      url: `${process.env.VUE_APP_API_ROUTE}/ticket/protocol/${ticketCode}`
    });
  },

  evaluateTicket( evaluationInfo ) {
    return api({
      method: 'POST',
      url: `${process.env.VUE_APP_API_ROUTE}/evaluationSystem`,
      data: evaluationInfo,
    });
  }
};
