module.exports = {
  CONTENT: {
    CITY: {
      name: 'Cariacica',
      title: 'Prefeitura',
      pageTitle: 'Ouvidoria',
      site: 'https://www.cariacica.es.gov.br/',
      NUMBER: {
        local: '162',
        other: '0800.283.9059',
        optional: '',
        workingHours: '24 horas:'
      },
      LINKS: {
        facebook: 'https://www.facebook.com/PrefeituraCariacica',
        linkedin: 'https://www.linkedin.com/company/prefeitura-municipal-de-cariacica',
        instagram: 'https://www.instagram.com/prefeituracariacica/',
        youtube: 'https://www.youtube.com/user/prefeituracariacica',
        admin: 'https://centraldeservicos-cariacica.upcities.app',
        aboutUrl: '',
        statisticsUrl: '',
        localNo: 'tel:162',
        otherNo: 'tel:0800.283.9059',
        optional: ''
      },
    },
    SUBHEADER: {
      text: 'Prefeitura de Cariacica',
      link: 'https://transparencia.cariacica.es.gov.br/esic.aspx',
    },
    HOME: {
      BANNER: {
        title: 'COMO PODEMOS TE AJUDAR?',
      },
      SERVICES: {
        title: 'Categorias de Serviços',
        subtitle: 'Escolha uma categoria para ver os serviços e informações relacionadas',
      },
      HELPBANNER: {
        title: 'Dê sua opinião!',
        subtitle: 'Seu feedback é importante para nós! Clique para escolher como deseja avaliar sua experiência.',
      },
      CARDS: {
        //url: nome da rota ou link externo
        DATA: {
          title: 'Acesse todas as categorias',
          subtitle: 'Explore as categorias disponíveis e saiba como ser atendido da melhor maneira',
          link: 'Ver todas',
          url: 'CategoryAll'
        },
        ACOMPANHE: {
          title: 'Minhas solicitações',
          subtitle: 'Veja o andamento das suas solicitações pelo número de protocolo ou login',
          link: 'Ver andamentos',
          url: 'Solicitations'
        },
        ATENDIMENTO: {
          title: 'Outros canais de atendimento',
          subtitle: 'Veja nossas opções de contato, incluindo atendimento por telefone ou presencial',
          link: 'Ver canais',
          url: 'https://www.cariacica.es.gov.br/'
        },
        DUVIDAS: {
          title: 'Dúvidas frequentes',
          subtitle: 'Encontre respostas para as perguntas mais comuns dos cidadãos',
          link: 'Tirar dúvidas',
          url: 'Faq'
        }
      },
      MOSTREQUESTEDBANNER: {
        title: 'ASSUNTOS MAIS SOLICITADOS',
      },
      APPBANNER: {
          title: 'EXPERIMENTE O CARIACICA DIGITAL',
          altTitle: 'Experimente o aplicativo de Cariacica!',
          subtitle: 'Baixe o app para interagir ainda mais com a prefeitura de Cariacica. Informações, orientações e serviços em um único lugar de forma intuitiva, rápida e fácil.',
          LINKS: {
            googlePlay: 'https://play.google.com/store/apps/details?id=br.com.uppersoft.cariacica',
            appleStore: 'https://apps.apple.com/br/app/cariacica-digital/id1613155049',
          }
      }
    },
    CATEGORIES: {
      title: 'Nossos serviços',
      subtitle: 'Escolha uma categoria para ver as informações e serviços relacionados',
    },
    SOLICITATIONS: {
      title: 'Acompanhe suas solicitações',
      search: 'Suas solicitações',
    },
    FAQ: {
      title: 'Dúvidas Frequentes',
    },
    AUTOCOMPLETE: {
      LOCATION: {
        lat: '-20.2655',
        lng: '-40.4203',
      },
      addressError: 'São permitidos apenas endereços da cidade de Cariacica'
    },
    INFOACCESS: {
      dashboard: 'Dados dos Pedidos de Informação',

      // os textos abaixo definem se os cards serao mostrados pela presença da rota ou link,
      // se link for interno adicionar o name da rota, links externos devem começar com http
      INDEX: {
        requestInformation: {
          id: 0,
          icon: 'InfoSquare',
          text: 'Faça seu Pedido de Informação pela Internet',
          link: ''
        },
        cards: [
          {
            id: 1,
            icon: 'EyeSquare',
            text: 'Acompanhar Pedido de Informação',
            link: 'Solicitations'
          },
          {
            id: 2,
            icon: 'PeopleMic',
            text: 'Faça seu Pedido de Informação pessoalmente ou por telefone',
            link: 'InfoContact'
          },
          {
            id: 3,
            icon: 'Faq',
            text: 'Perguntas Frequentes',
            link: 'InfoFAQ'
          },
          {
            id: 4,
            icon: 'Gavel',
            text: 'Legislações',
            link: 'InfoLegislation'
          },
          {
            id: 5,
            icon: 'DataSquare',
            text: 'Relatórios',
            link: ''
          },
          {
            id: 6,
            icon: 'Guide',
            text: 'Orientações sobre o Pedido de Acesso à Informação',
            link: '',
          },
          {
            id: 7,
            icon: 'ClipboardWatch',
            text: 'Informações sobre Prazos e Recursos',
            link: ''
          }
        ],
      },

      CONTACT: {
        headerText: 'O acesso à informação contribui para aumentar a eficiência do Poder Público, diminuir a corrupção e elevar a participação social. O acesso à informação é um direito do cidadão e um dever dos entes públicos. Todas as informações produzidas ou custodiadas pelo poder público são públicas e, portanto, acessíveis a todos os cidadãos, ressalvadas as hipóteses de sigilo legalmente estabelecidas em lei. A Lei Federal nº 12.527/2011 regula o acesso à informação previsto nos artigos 5º, inciso XXXIII, 37, §3º, inciso II e 216, §2º, todos da Constituição Federal. <br><br> A Lei Federal nº 12.527/2011 regula o acesso à informação previsto nos artigos 5º, inciso XXXIII, 37, §3º, inciso II e 216, §2º, todos da Constituição Federal.',
        cityLaw: 'No Município de Cariacica a LAI foi regulamentada em 2014 com a aprovação da Lei Municipal nº 5.133/2014 que criou o Serviço de Informação ao Cidadão - SIC em Cariacica. A gestão e a operação do SIC no Executivo Municipal está a cargo da Secretaria Municipal de Controle e Transparência (SEMCONT).',
        mailContact: [],
        inPersonContact: [
          'Para realizar a solicitação de acesso à informação presencialmente você pode visitar a Secretaria Municipal de Controle e Transparência - SEMCONT, localizada na sala 33 do Palácio Municipal - Sede da Prefeitura Municipal de Cariacica - Av. Mário Gurgel, nº 2.502 - Bairro Alto Lage - Cariacica/ES CEP: 29.151-570'
        ],
        phone: [
          {
            number: '(27) 3354-5953',
            label: ''
          }
        ],
        email: 'transparenciaweb@cariacica.es.gov.br',
        time: '9:00 às 18:00',
        important: [
          'Para ampliar as possibilidades do cidadão acessar à informação, foi criado o e-SIC que é o Sistema Eletrônico do Serviço de Informação ao Cidadão. O e-SIC é um sistema que centraliza os registros de entrada e respostas de todos os pedidos de acesso à informação dirigidos à Prefeitura Municipal de Cariacica. O e-SIC permite que qualquer pessoa, física ou jurídica, encaminhe pedidos de acesso à informação para a Prefeitura Municipal de Cariacica.',
          'Por este sistema, você acompanha a sua solicitação em tempo real pelo número de protocolo gerado e recebe a sua informação nos locais de realização do pedido, ou através do e-mail cadastrado.',
          'Também é possível consultar as respostas recebidas, entrar com recursos, apresentar reclamações, entre outras ações.'
        ]
      },

      LEGISLATION: [
        {
          label: 'Lei Federal n° 12.527/2011',
          link: 'http://www.planalto.gov.br/ccivil_03/_ato2011-2014/2011/lei/l12527.htm'
        },
        {
          label: 'Lei Municipal n° 5.133/2014',
          link: 'https://www.legislacaocompilada.com.br/cariacica/Arquivo/Documents/legislacao/html/L51332014.html'
        }
      ],

      FAQ: [],

      DEADLINES: [],

      ORIENTATION: {}
    },

    MANIFESTATION: {
      corruptionComplaint: ''
    }
  }
};