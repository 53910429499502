module.exports = {
  CONTENT: {
    CITY: {
      name: 'OAB-ES',
      title: 'Denúncias',
      pageTitle: 'Denúncias',
      site: 'https://www.oabes.org.br/',
      NUMBER: {
        local: '',
        other: '',
        optional: '',
        workingHours: ''
      },
      LINKS: {
        facebook: '',
        linkedin: '',
        instagram: '',
        youtube: '',
        admin: '',
        aboutUrl: '',
        statisticsUrl: '',
        localNo: '',
        otherNo: '',
        optional: ''
      },
    },
    SUBHEADER: {
      text: 'OAB-ES',
      link: '',
    },
    HOME: {
      BANNER: {
        title: 'COMO PODEMOS TE AJUDAR?',
      },
      SERVICES: {
        title: 'Categorias de Serviços',
        subtitle: 'Escolha uma categoria para ver os serviços e informações relacionadas',
      },
      HELPBANNER: {
        title: 'Dê sua opinião!',
        subtitle: 'Seu feedback é importante para nós! Clique para escolher como deseja avaliar sua experiência.',
      },
      CARDS: {
        //url: nome da rota ou link externo
        DATA: {
          title: 'Acesse todas as categorias',
          subtitle: 'Explore as categorias disponíveis e saiba como ser atendido da melhor maneira',
          link: 'Ver todas',
          url: 'CategoryAll'
        },
        ACOMPANHE: {
          title: 'Minhas solicitações',
          subtitle: 'Veja o andamento das suas solicitações pelo número de protocolo ou login',
          link: 'Ver andamentos',
          url: 'Solicitations'
        },
        ATENDIMENTO: {
          title: 'Outros canais de atendimento',
          subtitle: 'Veja nossas opções de contato, incluindo atendimento por telefone ou presencial',
          link: 'Ver canais',
          url: 'https://www.oab.org.br/'
        },
        DUVIDAS: {
          title: 'Dúvidas frequentes',
          subtitle: 'Encontre respostas para as perguntas mais comuns dos cidadãos',
          link: 'Tirar dúvidas',
          url: 'Faq'
        }
      },
      MOSTREQUESTEDBANNER: {
        title: 'Assuntos mais solicitados',
      },
      APPBANNER: {
        title: 'APLICATIVO OAB-ES',
        altTitle: 'Experimente o aplicativo de OAB-ES!',
        subtitle: 'Baixe o app para interagir ainda mais com a OAB-ES. Informações, orientações e serviços em um único lugar de forma intuitiva, rápida e fácil.',
        LINKS: {
          googlePlay: '',
          appleStore: '',
        }
      }
    },
    CATEGORIES: {
      title: 'Nossos serviços',
      subtitle: 'Escolha uma categoria para ver as informações e serviços relacionados',
    },
    SOLICITATIONS: {
      title: 'Acompanhe suas solicitações',
      search: 'Suas solicitações',
    },
    FAQ: {
      title: 'Dúvidas Frequentes',
    },
    AUTOCOMPLETE: {
      LOCATION: {
        lat: '-20.3207236',
        lng: '-40.3788378',
      },
      addressError: 'São permitidos apenas endereços de cidades do Espirito Santo'
    },

    INFOACCESS: {
      dashboard: 'Dados dos Pedidos de Informação',

      //se link for interno adicionar o name da rota, links externos devem começar com http
      INDEX: {
        requestInformation: {
          id: 0,
          icon: 'InfoSquare',
          text: 'Faça seu Pedido de Informação pela Internet',
          link: ''
        },
        cards: [
          {
            id: 1,
            icon: 'EyeSquare',
            text: 'Acompanhar Pedido de Informação',
            link: ''
          },
          {
            id: 2,
            icon: 'PeopleMic',
            text: 'Faça seu pedido de acesso à informação presencialmente, por telefone, e-mail ou correspondência',
            link: ''
          },
          {
            id: 3,
            icon: 'Faq',
            text: 'Perguntas Frequentes',
            link: ''
          },
          {
            id: 4,
            icon: 'Gavel',
            text: 'Legislação de Acesso à informação',
            link: ''
          },
          {
            id: 5,
            icon: 'DataSquare',
            text: 'Relatórios da LAI e Dados Estatísticos',
            link: ''
          },
          {
            id: 6,
            icon: 'Guide',
            text: 'Orientações sobre o Pedido de Acesso à Informação',
            link: '',
          },
          {
            id: 7,
            icon: 'ClipboardWatch',
            text: 'Informações sobre Prazos e Recursos',
            link: ''
          }
        ],
      },

      CONTACT: {
        headerText: '',
        cityLaw: '',
        inPersonContact: '',
        phone: [],
        email: '',
        time: '',
        important: []
      },

      LEGISLATION: [],

      FAQ: [],

      DEADLINES: [],

      ORIENTATION: {}
    },

    MANIFESTATION: {
      corruptionComplaint: ''
    }
  },
};