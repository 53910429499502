//Imports
import Vue from 'vue';
import '@/registerServiceWorker';
import '@/config/globalComponents/index'; // GlobalComponents
import '@/config/veeValidate/index'; // VeeValidate
import '@/config/vueTelInput/index'; // Telephone Input
import '@/config/vueSanitize/index'; // vueSanitize
import '@/config/vmodal/index'; // Modal
import '@/config/vueTheMask/index'; // Vue mask
import '@/config/sentry/index'; // Sentry
import VueGtag from 'vue-gtag';
import i18n from '@/config/i18n/index';
import dayjs from '@/config/dayjs/index'; // DayJS
import store from './store/index'; // Store
import router from './router/index'; // Router
import App from './App.vue';
import { api, setAuthHeader } from '@/config/axios/index';
import { currentRealm } from '@/utils/realm.js';

// --- Prototype
Vue.prototype.$dayjs = dayjs;
Vue.prototype.currentRealm = currentRealm;

let analyticsCity = process.env.VUE_APP_ANALYTICS_GTAG_CITY ?
  { id: process.env.VUE_APP_ANALYTICS_GTAG_CITY } :
  {};

Vue.use(VueGtag, {
  includes: [analyticsCity],
  config: { id: process.env.VUE_APP_ANALYTICS_GTAG }
});

Vue.config.productionTip = false;

new Vue({
  name: 'AppVue',
  router,
  store,
  i18n,
  async created(){
    if (store.getters.userIsLogged) {
      setAuthHeader(store.getters.userToken);
      await store.dispatch('initKeycloak', 'check-sso');
    } else {
      await store.dispatch('initKeycloak');
    }

    api.interceptors.request.use(
      function (config) {
        store.dispatch('checkTokenIsExpired');

        return config;
      },
      async (error) => {
        await this.$router.replace({ name: 'Home', query: {} }).catch(() => {});
        store.dispatch('userLogout');

        return Promise.reject(error);
      },
    );

    api.interceptors.response.use(
      (response) => response,
      async (error) => {
        if (error.response) {
          //if status is 401 the user is redirected to login and can proceed with ticket creation flow
          if (error.response.status === 401 && (router.currentRoute.name === 'ManifestationType' || router.currentRoute.name === 'SolicitationType')) {
            await store.dispatch('initKeycloak');

            return;
          }

          //fallback: stops user from trying to send a ticket with invalid auth
          if (error.response.status === 401 && (router.currentRoute.name === 'SolicitationFinish' || router.currentRoute.name === 'ManifestationFinish')) {
            store.dispatch('setUnauthorizedError', true);

            return;
          }

          /**
           * Checking if some request response has status code 401
           * if it's occurs then logout the user
          */
          if (error.response.status === 401) {
            await store.dispatch('userLogout');
          }
        }

        return Promise.reject(error);
      },
    );
  },
  render: (h) => h(App)
}).$mount('#app');

