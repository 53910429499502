module.exports = {
  CONTENT: {
    CITY: {
      name: 'Cachoeiro de Itapemirim',
      title: 'Prefeitura',
      pageTitle: 'Ouvidoria',
      site: 'https://www.cachoeiro.es.gov.br',
      NUMBER: {
        local: '(28) 98814-3357',
        other: '',
        optional: '',
        workingHours: '24 horas:'
      },
      LINKS: {
        facebook: 'https://www.facebook.com/prefcachoeiro/',
        linkedin: 'https://www.linkedin.com/company/prefeitura-municipal-de-cachoeiro-de-itapemirim/',
        instagram: 'https://www.instagram.com/prefcachoeiro/',
        youtube: 'https://www.youtube.com/user/semcoscachoeiro',
        admin: 'https://centraldeservicos-cachoeiro.upcities.app/',
        aboutUrl: '',
        statisticsUrl: '',
        localNo: 'tel:(28)988143357',
        otherNo: '',
        optional: ''
      },
    },
    SUBHEADER: {
      text: 'Prefeitura de Cachoeiro',
      link: 'Information',
    },
    HOME: {
      BANNER: {
        title: 'COMO PODEMOS TE AJUDAR?',
      },
      SERVICES: {
        title: 'Categorias de Serviços',
        subtitle: 'Escolha uma categoria para ver os serviços e informações relacionadas',
      },
      HELPBANNER: {
        title: 'Dê sua opinião!',
        subtitle: 'Seu feedback é importante para nós! Clique para escolher como deseja avaliar sua experiência.',
      },
      CARDS: {
        //url: nome da rota ou link externo
        DATA: {
          title: 'Proteção de Dados Pessoais',
          subtitle: 'Saiba como protegemos seus dados pessoais e sua privacidade',
          link: 'Ver todas',
          url: 'https://fala.cachoeiro.es.gov.br:8443/FormularioLGPD.aspx',
        },
        ACOMPANHE: {
          title: 'Legislação e Relatórios',
          subtitle: 'Acesse leis e relatórios governamentais para entender seus direitos e obrigações',
          link: 'Acessar documentos',
          url: 'Docs',
        },
        ATENDIMENTO: {
          title: 'Outros canais de atendimento',
          subtitle: 'Veja nossas opções de contato, incluindo atendimento por  telefone ou presencial',
          link: 'Ver canais',
          url: 'ManifestationContact'
        },
        DUVIDAS: {
          title: 'Dúvidas frequentes',
          subtitle: 'Encontre respostas para as perguntas mais comuns dos cidadãos',
          link: 'Tirar dúvidas',
          url: 'https://perguntaserespostasprefcachoeiro.my.canva.site/'
        }
      },
      MOSTREQUESTEDBANNER: {
        title: 'Assuntos mais solicitados',
      },
      APPBANNER: {
        title: 'EXPERIMENTE O CACHOEIRO DIGITAL',
        altTitle: 'Experimente o aplicativo de Cachoeiro!',
        subtitle: 'Baixe o app para interagir ainda mais com a prefeitura de Cachoeiro de Itapemirim. Informações, orientações e serviços em um único lugar de forma intuitiva, rápida e fácil.',
        LINKS: {
          googlePlay: 'https://prml.me/JIVY',
          appleStore: 'https://prml.me/JJs7',
        }
      }
    },
    CATEGORIES: {
      title: 'Nossos serviços',
      subtitle: 'Escolha uma categoria para ver as informações e serviços relacionados',
    },
    SOLICITATIONS: {
      title: 'Acompanhe suas solicitações',
      search: 'Suas solicitações',
    },
    FAQ: {
      title: 'Dúvidas Frequentes',
    },
    AUTOCOMPLETE: {
      LOCATION: {
        lat: '-20.8494',
        lng: '-41.1132',
      },
      addressError: 'São permitidos apenas endereços da cidade de Cachoeiro'
    },
    INFOACCESS: {
      dashboard: 'Dados dos Pedidos de Informação',

      // os textos abaixo definem se os cards serao mostrados pela presença da rota ou link,
      // se link for interno adicionar o name da rota, links externos devem começar com http
      INDEX: {
        requestInformation: {
          id: 0,
          icon: 'InfoSquare',
          text: 'Faça seu Pedido de Informação pela Internet',
          link: 'ManifestationType'
        },
        cards: [
          {
            id: 1,
            icon: 'EyeSquare',
            text: 'Acompanhar Pedido de Informação',
            link: 'Solicitations'
          },
          {
            id: 2,
            icon: 'PeopleMic',
            text: 'Faça seu Pedido de Informação pessoalmente ou por telefone',
            link: 'InfoContact'
          },
          {
            id: 3,
            icon: 'Faq',
            text: 'Perguntas Frequentes',
            link: 'InfoFAQ'
          },
          {
            id: 4,
            icon: 'Gavel',
            text: 'Legislações',
            link: 'InfoLegislation'
          },
          {
            id: 5,
            icon: 'DataSquare',
            text: 'Relatórios',
            link: ''
          },
          {
            id: 6,
            icon: 'Guide',
            text: 'Orientações sobre o Pedido de Acesso à Informação',
            link: ''
          },
          {
            id: 7,
            icon: 'ClipboardWatch',
            text: 'Informações sobre Prazos e Recursos',
            link: ''
          }
        ],
      },

      CONTACT: {
        headerText: 'A Lei nº 12.527 de 18 de Novembro de 2011 regulamenta o direito de acesso às informações públicas previsto na Constituição do Estado do Espírito Santo, estabelecendo procedimentos, prazos e obrigações.',
        cityLaw: '',
        mailContact: [],
        inPersonContact: [
          'Para realizar a solicitação de acesso à informação presencialmente você pode visitar a Ouvidoria Geral do Município, localizada na Rua Brahim Antônio Seder, n° 34, térreo - Centro, Ed. Centro Administrativo Hélio Carlos Manhães (antigo SESC).',
        ],
        phone: [
          {
            number: '156',
            label: ''
          },
          {
            number: '(28) 98814-3357',
            label: '(WhatsApp Ouvidoria)'
          }
        ],
        email: '',
        time: '7:00 às 17:00',
        important: [
          'Não é necessário justificativa para realização do pedido de informação;',
          'O pedido de informação deve ser respondido em até 20 dias, podendo ser prorrogado por mais 10 dias, mediante justificativa;',
          'Qualquer pessoa física ou jurídica tem o direito de receber informações públicas dos órgãos e entidades;',
          'O sigilo dos dados pessoais será mantido.'
        ]
      },

      LEGISLATION: [
        {
          label: 'Lei de Acesso à Informação nº 12.527/2011',
          link: 'https://www.planalto.gov.br/ccivil_03/_Ato2011-2014/2011/Lei/L12527.htm'
        },
        {
          label: 'Lei n° 14.129/2021 que altera a Lei de Acesso à Informação nº 12.527/2011',
          link: 'http://www.planalto.gov.br/ccivil_03/_Ato2019-2022/2021/Lei/L14129.htm'
        },
        {
          label: 'Decreto 32.188 que dispõe sobre a regulamentação dos procedimentos para a garantia do acesso à informação e para a classificação de informações sob restrição de acesso, no âmbito do poder executivo municipal.',
          link: 'https://leis.cachoeiro.es.gov.br:8081/normas/images/leis/pdf/NOVO_DECRETO_LAI.pdf'
        }
      ],

      FAQ: [
        {
          id: 0,
          question: 'O que é a Lei de Acesso à Informação (LAI)?',
          answer: 'A Lei nº 12.527, sancionada em 18 de novembro de 2011, regulamenta o direito constitucional de acesso dos cidadãos às informações públicas e é aplicável aos três poderes da União, dos estados, do Distrito Federal e dos municípios.'
        },
        {
          id: 1,
          question: 'Em caso de indeferimento de acesso a informações ou negativa do acesso é possível entrar com recurso?',
          answer: 'Sim. Após ciência da resposta é possível entrar com recurso em até 10 dias.'
        },
        {
          id: 2,
          question: 'Qual é o prazo para resposta de um pedido de informação pública?',
          answer: 'O pedido de informação deve ser respondido em até 20 dias, podendo ser prorrogado por mais 10 dias, mediante justificativa.'
        },
        {
          id: 3,
          question: 'Quem pode solicitar?',
          answer: 'Qualquer pessoa física ou jurídica tem o direito de receber informações públicas dos órgãos e entidades.'
        },
        {
          id: 4,
          question: 'Quem é responsável por julgar os recursos dos pedidos de informação pública?',
          answer: 'De acordo com a Instrução Normativa 003/2023, o recurso em 1ª Instância é direcionado a Autoridade Superior do Órgão que prestou a informação. Já em 2ª Instância o recurso é analisado pelo(a)  Controlador(a) Geral do Município. Caso haja a necessidade de analisar o  recurso em 3ª Instancia a responsabilidade será direcionada a Comissão Mista de Reavaliação.',
          link: {
            text: 'Instrução Normativa 003/2023',
            ref: 'https://transparencia.cachoeiro.es.gov.br/uploads/norma/20230302181343-decreto-municipal-n-32665-2023-aprova-a-in-sci-n-03-2.pdf'
          }
        },
        {
          id: 5,
          question: 'Como faço para saber o fluxo completo de um pedido de informação desde o momento da abertura até a resposta ou abertura de recurso?',
          answer: 'Todo o fluxo de abertura, resposta e recursos de um pedido de informação está contemplado na Instrução Normativa 003/2023',
          link: {
            text: 'Instrução Normativa 003/2023',
            ref: 'https://transparencia.cachoeiro.es.gov.br/uploads/norma/20230302181343-decreto-municipal-n-32665-2023-aprova-a-in-sci-n-03-2.pdf'
          }
        }
      ],

      DEADLINES: [],

      ORIENTATION: {}
    },

    MANIFESTATION: {
      corruptionComplaint: 'https://fala.cachoeiro.es.gov.br:8443/DenunciaCorrupcao.aspx?idTipo=11'
    },

    DOCS: {
      cards: [
        {
          id: 1,
          icon: 'Gavel',
          text: 'Legislações',
          link: 'DocLegislation'
        },
        {
          id: 2,
          icon: 'DataSquare',
          text: 'Relatórios',
          link: 'DocReports'
        }
      ],
      legislation: [
        {
          label: 'Decreto nº 28.824',
          text: 'Regulamenta a aplicação das disposições da lei federal nº 13.460 de 26/06/2017, que dispõe sobre a participação, proteção e defesa dos direitos do usuário de serviços públicos da administração pública',
          link: 'https://leis.cachoeiro.es.gov.br:8081/portalcidadao/arquivos/Legislacao/DECRETO288242019.html',
        },
        {
          label: 'Decreto nº 28.903',
          text: 'Regulamenta a aplicação das disposições da lei federal nº 13.460 de 26/06/2017, que dispõe sobre a participação, proteção e defesa dos direitos do usuário de serviços públicos da administração pública.',
          link: 'https://leis.cachoeiro.es.gov.br:8081/portalcidadao/arquivos/Legislacao/DECRETO289032019.html',
        },
        {
          label: 'Decreto nº 31.831',
          text: 'Dispõe sobre as salvaguardas de proteção à identidade dos denunciantes de ilícitos e de irregularidades praticadas contra a administração pública municipal direta e indireta, e dá outras providências.',
          link: 'https://cachoeiro.legislacaocompilada.com.br/Arquivo/Documents/legislacao/image/D318312022.pdf?identificador=30003A004C00',
        },
      ],
    }
  }
};