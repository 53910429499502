import Vue from 'vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';

Vue.component('ModalElement', () => import('@/components/common/ModalElement.vue'));
Vue.component('Loading', () => import('@/components/common/Loading.vue'));
Vue.component('SvgElement', () => import('@/components/base/svg/SVG.vue')); //SVG
Vue.component('SelectComponent', () => import('@/components/common/SelectComponent.vue')); //Select
Vue.component('AvatarPlaceholder', () => import('vue-avatar')); //Avatar
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);



