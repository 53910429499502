
/**
 * Verifica se um CPF é válido
 *
 * @param string número do CPF para validação
 * @return boolean TRUE para CPF válido e FALSE para cpf inválido
*/

export function cpfValidator(cpfNumber) {
  let sum;

  let rest;

  sum = 0;

  const cpf = cpfNumber.replace(/\D+/g, '');

  if (/^(.)\1+$/.test(cpf)) {
    return false;
  }

  for (let i = 1; i <= 9; i += 1) {
    sum += parseInt(cpf.substring(i - 1, i), 10) * (11 - i);
  }

  rest = (sum * 10) % 11;

  if ((rest === 10) || (rest === 11)) {
    rest = 0;
  }

  if (rest !== parseInt(cpf.substring(9, 10), 10)) {
    return false;
  }

  sum = 0;

  for (let i = 1; i <= 10; i += 1) {
    sum += parseInt(cpf.substring(i - 1, i), 10) * (12 - i);
  }

  rest = (sum * 10) % 11;

  if ((rest === 10) || (rest === 11)) {
    rest = 0;
  }

  if (rest !== parseInt(cpf.substring(10, 11), 10)) {
    return false;
  }

  return true;
}

export function susValidator(susNumber) {
	if (susNumber.matches('[1-2]\\d{10}00[0-1]\\d') || susNumber.matches('[7-9]\\d{14}')) {
		return somaPonderada(susNumber) % 11 === 0;
	}

	return false;
}

function somaPonderada(s) {
	var cs = s.toCharArray();
	var soma = 0;

	for (var i = 0; i < cs.length; i++) {
		soma += cs.digit(cs[i], 10) * (15 - i);
	}

	return soma;
}

export function formatFileSize(rawSize) {
  const units = ['B', 'kB', 'MB', 'GB', 'TB'];

  let i = 0;

  if(rawSize !== 0) {
    i = Math.floor(Math.log(rawSize) / Math.log(1024));
  }
  const formatedSize = (rawSize / Math.pow(1024, i));

  return `${formatedSize} ${units[i]}`;
}

export function setCookie(name, value, days = 7) {
  const expires = (new Date(Date.now() + days*24*60*60*1000)).toUTCString();

  document.cookie = `${name}=${value}; expires=${expires}; path=/`;
}

export function getCookie(name) {
  return document.cookie.match(`(^|;)\\s*${name}\\s*=\\s*([^;]+)`)?.pop() || '';
}

export function getCachedValue(key) {
  const cookie = getCookie(key) || null;

  if (!cookie) {
    localStorage.removeItem(key);

    return null;
  }
  const subjects = JSON.parse(localStorage.getItem(key)) || null;

  return subjects;
}

export function cacheValue(key, value) {
  setCookie(key, 'cached');
  localStorage.setItem(key, JSON.stringify(value));
}

export function showApiError(error, type = 'error') {
  return { messageError: error.data.message, type: type };
}