import Keycloak from 'keycloak-js';
import store from '.././store';
import { api } from '@/config/axios/index';

var url = process.env.VUE_APP_KEYCLOAK_URL;

let userInfo = {
    name: '',
    email: '',
    authenticated: false,
    userToken: '',
    avatar: null,
    keycloak: null,
};

const keycloak = new Keycloak({
  url: url,
  realm: process.env.VUE_APP_KEYCLOAK_REALM,
  clientId: process.env.VUE_APP_KEYCLOAK_CLIENTID,
});

export default {
  async initKeycloak(onload) {
    this.updateToken();

    if(onload === 'check-sso'){
      await keycloak.init({
        onLoad: onload,
        silentCheckSsoRedirectUri: `${window.location.origin}/silent-check-sso.html`,
        checkLoginIframe: true,
        pkceMethod: 'S256',
      })
      .then((auth)=>{
        if(auth){
          userInfo = {
            name: keycloak.idTokenParsed.name,
            email: keycloak.idTokenParsed.email,
            phone: keycloak.idTokenParsed.phone_number?.replace(/(\d{2})?(\d{5})?(\d{4})/, '($1) $2-$3'),
            birthdate: keycloak.idTokenParsed.birth_date,
            cpf: keycloak.idTokenParsed.preferred_username,
            avatar: keycloak.idTokenParsed.avatar,
            authenticated: keycloak.authenticated,
            userToken: keycloak.token,
            refreshToken: keycloak.refreshToken,
            keycloak: keycloak,
            userId: keycloak.idTokenParsed.sub,
          };
        }else{
          userInfo.authenticated = keycloak.authenticated;
          userInfo.keycloak = keycloak;
        }
      })
      .catch(function() {
        alert('Ocorreu um erro ao inicializar o sistema');
      });
    }else{
      await keycloak.init({
        checkLoginIframe: false,
        pkceMethod: 'S256',
      })
      .then((auth) => {
        if(auth){
          userInfo = {
            name: keycloak.idTokenParsed.name,
            email: keycloak.idTokenParsed.email,
            phone: keycloak.idTokenParsed.phone_number?.replace(/(\d{2})?(\d{5})?(\d{4})/, '($1) $2-$3'),
            birthdate: keycloak.idTokenParsed.birth_date,
            cpf: keycloak.idTokenParsed.preferred_username,
            avatar: keycloak.idTokenParsed.avatar,
            authenticated: keycloak.authenticated,
            userToken: keycloak.token,
            refreshToken: keycloak.refreshToken,
            keycloak: keycloak,
            userId: keycloak.idTokenParsed.sub,
          };
        }else{
          userInfo.authenticated = keycloak.authenticated;
          userInfo.keycloak = keycloak;
        }
      })
      .catch(function(e) {
          alert(`Ocorreu um erro ao inicializar o sistema: ${e}`);
      });
    }

    return userInfo;
  },

  async updateToken(){
    keycloak.onTokenExpired = function () {
      keycloak.updateToken(300).then(async function() {
        store.commit('KEYCLOAK_UPDATE_USER_TOKEN', keycloak.token);
      }).catch(async function () {
        userInfo.userToken = null;
        userInfo.authenticated = false;
        await keycloak.logout();
      });
    };
  },

  async autologinKeycloak(token, refreshToken, clientId){
    const autoLoginkeycloak = new Keycloak({
      url: process.env.VUE_APP_KEYCLOAK_URL,
      realm: process.env.VUE_APP_KEYCLOAK_REALM,
      clientId: clientId,
    });

    await autoLoginkeycloak.init({
      onLoad: 'check-sso',
      checkLoginIframe: false,
      token: token,
      refreshToken: refreshToken,
    })
    .then((authenticated) => {
      if(authenticated){
        userInfo = {
          name: autoLoginkeycloak.tokenParsed.name,
          email: autoLoginkeycloak.tokenParsed.email,
          phone: autoLoginkeycloak.tokenParsed.phone_number?.replace(/(\d{2})?(\d{5})?(\d{4})/, '($1) $2-$3'),
          birthdate: autoLoginkeycloak.tokenParsed.birth_date,
          cpf: autoLoginkeycloak.tokenParsed.preferred_username,
          avatar: autoLoginkeycloak.tokenParsed.avatar,
          authenticated: autoLoginkeycloak.authenticated,
          userToken: autoLoginkeycloak.token,
          keycloak: autoLoginkeycloak,
          userId: autoLoginkeycloak.tokenParsed.sub,
        };
      }else{
        userInfo.authenticated = autoLoginkeycloak.authenticated;
        userInfo.keycloak = autoLoginkeycloak;
      }
    })
    .catch(function(e) {
      alert(`failed to initialize ${e}`);
    });

    return userInfo;
  },

  getUserInfo(url) {
    return api({
      method: 'GET',
      url: url,
    });
  }
};
